import React from 'react';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import NavThree from '../components/NavThree';
import SliderOne from '../components/SliderOne';
import BlogThree from '../components/BlogThree';

import CourseCatThree from '../components/CourseCatThree';
import CourseCatOne from '../components/CourseCatOne';
import CallToActionOne from '../components/CallToActionOne';
import TestimonialOne from '../components/TestimonialOne';
import VideoThree from '../components/VideoThree';

const HomePageThree = () => {
  return (
    <Layout pageTitle='Schools | Lagos University Teaching Hospital'>
      <NavThree />
      <SliderOne />
      {/* <CourseCatThree /> */}
      <CourseCatOne />
      <CallToActionOne />
      {/* <TestimonialOne /> */}
      <VideoThree />
      {/* <BlogThree/> */}
      <Footer />
    </Layout>
  );
};

export default HomePageThree;
